const { Box } = require('@mui/material')

export function TabWrapper({
  children,
  tabValue,
  currentValue,
  keepInDom = false,
  tabBoxProps,
  ...other
}) {
  let renderChildren = true
  if (!keepInDom && tabValue !== currentValue) {
    renderChildren = false
  }

  return (
    <div
      role="tabpanel"
      hidden={tabValue !== currentValue}
      id={`simple-tabpanel-${currentValue}`}
      aria-labelledby={`simple-tab-${currentValue}`}
      className="w-100"
      style={{ display: tabValue === currentValue ? 'block' : 'none' }}
      {...other}
    >
      <Box sx={{ p: 3 }} {...tabBoxProps}>
        {renderChildren && children}
      </Box>
    </div>
  )
}
