import { DEFAULT_PLATFORM_TAB_SETTING } from 'src/common/types/tabSettings/tabSettingsObject'
import { ALL_ENTITY_TYPES } from 'src/config/common/genericListingAndFormConfig'
import { getReduxStoreData } from 'src/getDataFromRedux'
import { fetchFunction, fetchPaginatedFunction } from 'src/services'
import { getSetupDataFromLocalStorage } from 'src/services/BrowserStorage/localStorageUtils'
import { GENERIC_APIS } from 'src/services/genericApis'
import { SETUP_APIS } from 'src/views/setup/utils/api-utils'

export async function transformInitialMetaTabSettingData(fetchedData) {
  const allTabs =
    (await getReduxStoreData()?.metaTabs) ||
    getSetupDataFromLocalStorage()?.metaTabs ||
    fetchFunction(SETUP_APIS.tiles.get.url, {
      includeFeatures: false,
    })
  const androidData = fetchedData?.android?.[0] || DEFAULT_PLATFORM_TAB_SETTING
  const iosData = fetchedData?.ios?.[0] || DEFAULT_PLATFORM_TAB_SETTING
  const webData = fetchedData?.web?.[0] || DEFAULT_PLATFORM_TAB_SETTING

  const androidTilePolicies = getTilePoliciesInAPlatform(androidData?.tilePolicyMap, allTabs) || []
  const webTilePolicies = getTilePoliciesInAPlatform(iosData?.tilePolicyMap, allTabs) || []
  const iosTilePolicies = getTilePoliciesInAPlatform(webData?.tilePolicyMap, allTabs) || []

  const uniqueTilePolicies = getUniqueTilePolicies([
    ...androidTilePolicies,
    ...webTilePolicies,
    ...iosTilePolicies,
  ])

  const fetchedTilePoliciesArray = await getRelevantTilePolicies(uniqueTilePolicies)

  const androidFinalTilePoliciesMap = relevantTilePoliciesArrayToMap(
    androidData?.tilePolicyMap,
    fetchedTilePoliciesArray,
    allTabs,
  )
  const webFinalTilePoliciesMap = relevantTilePoliciesArrayToMap(
    webData?.tilePolicyMap,
    fetchedTilePoliciesArray,
    allTabs,
  )
  const iOSFinalTilePoliciesMap = relevantTilePoliciesArrayToMap(
    iosData?.tilePolicyMap,
    fetchedTilePoliciesArray,
    allTabs,
  )

  console.log({ androidPols: androidFinalTilePoliciesMap })

  return {
    ...fetchedData,
    android: { ...androidData, tilePolicyMap: androidFinalTilePoliciesMap },
    ios: { ...iosData, tilePolicyMap: iOSFinalTilePoliciesMap },
    web: { ...webData, tilePolicyMap: webFinalTilePoliciesMap },
  }
}

async function getRelevantTilePolicies(allTilePolicies) {
  const uniqueTilePoliciesNonDefault = allTilePolicies?.reduce((acc, tilePolicy) => {
    const foundPolicy =
      tilePolicy?.tileId === 'default'
        ? true
        : acc?.find((tPolicy) => tPolicy?.tileId === tilePolicy?.tileId)
    !foundPolicy && acc.push(tilePolicy?.tileId)

    return acc
  }, [])
  // const defaultPolicies = allTilePolicies?.reduce((acc, tilePolicy) => {
  //   const foundPolicy = acc?.find((tPolicy) => tPolicy?.tabId === tilePolicy?.tabId)
  //   !foundPolicy && acc.push(tilePolicy?.tabId)

  //   return acc
  // }, [])
  const defaultPolicies = []
  let fetchedPolicies = []
  if (uniqueTilePoliciesNonDefault?.length > 0)
    fetchedPolicies = await fetchPaginatedFunction(GENERIC_APIS.entity.getPaginated, {
      entityType: ALL_ENTITY_TYPES.tilePolicy,
      ids: uniqueTilePoliciesNonDefault?.join(','),
    })
  const fetchedRelevantDefaultPolicies = await Promise.all(
    defaultPolicies?.map(async (defPolicy, idx) => {
      const defaultTileResp = await fetchPaginatedFunction(GENERIC_APIS.entity.getPaginated, {
        entityType: ALL_ENTITY_TYPES.tilePolicy,
        active: true,
        category: defPolicy,
      })
      return defaultTileResp?.[0]
    }),
  )

  return [...(fetchedPolicies || []), ...(fetchedRelevantDefaultPolicies || [])]
}

function getTilePoliciesInAPlatform(tilePolicyMap, tabs) {
  return tabs
    ?.filter((tab) => tab?.curatable)
    .map((key) => {
      return {
        tabId: key?.id,
        tileId: tilePolicyMap?.[key?.id] || 'default',
      }
    })
    ?.filter((tilePolicy) => tilePolicy?.tileId !== 'default')
}

function getUniqueTilePolicies(allTilePoliciesWithTabs) {
  return allTilePoliciesWithTabs?.reduce((acc, tilePolicy) => {
    let isTilePolicyAlreadyPresent = acc?.find(
      (accTilePolicy) => accTilePolicy?.tileId === tilePolicy?.tileId,
    )
    if (tilePolicy?.tileId === 'default') {
      isTilePolicyAlreadyPresent = acc?.find(
        (accTilePolicy) =>
          accTilePolicy?.tileId === tilePolicy?.tileId &&
          accTilePolicy?.tabId === tilePolicy?.tabId,
      )
    }

    !isTilePolicyAlreadyPresent && acc.push(tilePolicy)

    return acc
  }, [])
}

function relevantTilePoliciesArrayToMap(initialTilePoliciesMap, fetchedTilePolicies, allTabs) {
  return allTabs
    ?.filter((tab) => tab?.curatable)
    ?.reduce((acc, tab) => {
      const tilePolicyId = initialTilePoliciesMap?.[tab?.id] || 'default'
      let tilePolicyToSet = null

      tilePolicyToSet = fetchedTilePolicies?.find((tPolicy) => {
        return tPolicy?.id === tilePolicyId
      })

      if (tilePolicyId === 'default') {
        tilePolicyToSet = fetchedTilePolicies?.find((tPolicy) => {
          return tPolicy?.active && tPolicy?.category === tab?.id
        })
      }

      return { ...acc, [tab?.id]: tilePolicyToSet }
    }, {})
}

// RELEVANT TILE POLICIES CODE OLD
// return await Promise.all(
//   tabs.map(async (tab) => {
//     const tilePoliciesToFind = allTilePolicies?.filter(
//       (tilePolicy) => tilePolicy?.tabId === tab?.id,
//     )
//     const tileResp = await fetchPaginatedFunction(GENERIC_APIS.entity.getPaginated, {
//       entityType: ALL_ENTITY_TYPES.tilePolicy,
//       ids: tilePoliciesToFind,
//       category: tab.id,
//     })
//     if (tilePoliciesToFind?.find((tilePolicy) => tilePolicy?.tileId === 'default')) {
//       const defaultTileResp = await fetchPaginatedFunction(GENERIC_APIS.entity.getPaginated, {
//         entityType: ALL_ENTITY_TYPES.tilePolicy,
//         active: true,
//         category: tab.id,
//       })
//       tileResp.push(defaultTileResp?.[0])
//     }
//     return tileResp
//   }),
// )
