import { FormControlLabel, Grid, Switch } from '@mui/material'
import React, { useEffect, useState } from 'react'
import GridSectionWrapper from 'src/components/controlCenter/common/sectionComponents/GridSectionWrapper'
import {
  CCFormInput,
  CustomReactSelect,
  selectTypes,
} from 'src/components/controlCenter/FormComponents'
import {
  getUserTranslationLanguagesPref,
  setUserTranslationLanguagesPref,
} from 'src/services/BrowserStorage/localStorageUtils'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'

export default function InstructionForm({
  initialObject = {},
  imageObject: instruction,
  setImageObject: setInstruction,
  handleChangeObject,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  handleReactSelectChange,
  handleRichTextChange = () => {},
  handleImageChange = () => {},
  entity,
  allImages,
  customOptions = {},
  disabled = false,
  formMode,
}) {
  const [store] = useAppSettingsStore()

  const [userTranslationLangPref, setUserTranslationLangPref] = useState(
    getUserTranslationLanguagesPref(),
  )

  function handleChangeTranslations(e, langId) {
    setInstruction((prev) => ({
      ...prev,
      translations: {
        ...prev?.translations,
        [langId]: {
          ...(prev?.translations?.[langId] || {}),
          [e.target.id]: e.target.value,
        },
      },
    }))
  }

  useEffect(() => {
    setUserTranslationLanguagesPref(userTranslationLangPref)
  }, [userTranslationLangPref])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              checked={instruction?.enabled}
              onChange={(e) => {
                setInstruction((prev) => ({ ...prev, enabled: e.target.checked }))
              }}
              name="enabled"
              color="primary"
            />
          }
          label="Enabled"
        />
      </Grid>
      <Grid item xs={12}>
        <CCFormInput
          id="title"
          label="Title"
          required
          value={instruction?.title}
          onChange={handleChangeObject}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomReactSelect
          isMulti
          id="userPrefLang"
          value={userTranslationLangPref}
          options={store?.languages
            ?.slice()
            ?.sort((a, b) => a?.languageName?.localeCompare(b?.languageName))}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.languageName}
          selectType={selectTypes.string}
          label={'Preferred Translation Languages'}
          onChangeEvent={(e) => {
            setUserTranslationLangPref(e.target.value)
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <GridSectionWrapper heading="Translations">
          <Grid item xs={12}>
            {userTranslationLangPref?.map((langId) => (
              <TranslationsComponent
                key={langId}
                translations={instruction?.translations}
                langId={langId}
                langName={store?.languages?.find((l) => l?.id === langId)?.languageName}
                disabled={disabled}
                handleChange={(e) => handleChangeTranslations(e, langId)}
              />
            ))}
          </Grid>
        </GridSectionWrapper>
      </Grid>
    </Grid>
  )
}

function TranslationsComponent({ translations, langId, langName, disabled, handleChange }) {
  return (
    <Grid item xs={12}>
      <CCFormInput
        id="title"
        label={`${langName} Title`}
        value={translations?.[langId]?.title}
        onChange={handleChange}
        disabled={disabled}
      />
    </Grid>
  )
}

{
  /* <CCFormInput
              id="title"
              label="Hindi Title"
              value={instruction?.translations?.hi?.title}
              onChange={(e) =>
                setInstruction((prev) => ({
                  ...prev,
                  translations: {
                    ...prev?.translations,
                    hi: {
                      ...(prev?.translations?.hi || {}),
                      title: e.target.value,
                    },
                  },
                }))
              }
              disabled={disabled}
            /> */
}
