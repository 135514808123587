export function getSetupDataFromLocalStorage() {
  const setupData = localStorage.getItem('setup')
  return setupData ? JSON.parse(setupData) : null
}

export function getUserAuthoritiesFromLocalStorage() {
  const authorities = localStorage.getItem('authorities')
  return authorities ? JSON.parse(authorities) : null
}
export function setUserAuthoritiesToLocalStorage(authorities) {
  localStorage.setItem('authorities', JSON.stringify(authorities))
}

export function getTokenFromLocalStorage() {
  return localStorage.getItem('token')
}

export function getUserTranslationLanguagesPref() {
  const languages = localStorage.getItem('translationPref')
  return languages ? JSON.parse(languages) : null
}

export function setUserTranslationLanguagesPref(languages) {
  localStorage.setItem('translationPref', JSON.stringify(languages))
}
