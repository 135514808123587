import { DragHandle, South } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { sortFn } from 'src/components/controlCenter/backgroundSlides/TimeLineDnD'
import ConfirmationModal from 'src/components/controlCenter/ConfirmationModal'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import { GetDetailedEntity, SaveEntity } from 'src/utilities/apiGetters'
import { array_move } from 'src/views/curatedLists/utils/curatedListData-utils'

export default function ReorderDrugAdviceItems({
  apiParam,
  listingData: states,
  handleClose,
  handleRefresh,
}) {
  const [reorderedItems, setReorderedItems] = useState([])
  const [changes, setChanges] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [allDrugAdviceItems, setAllDrugAdviceItems] = useState([])
  const [loading, setLoading] = useState(false)

  async function saveOrder() {
    const response = await Promise.all(
      reorderedItems.map(async (item, idx) => {
        const drugEntity = await GetDetailedEntity(apiParam, item)
        const resp = await SaveEntity(
          apiParam,
          { ...drugEntity, order: idx },
          () => {},
          () => {},
        )
      }),
    )
    handleRefresh()
    handleClose()
  }

  async function getDrugAdviceItems() {
    const drugAdviceItemsResponse = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
      entityType: apiParam,
      page: 0,
      pageSize: 999,
      availableToAll: true,
    })
    if (drugAdviceItemsResponse.status === 200) {
      const drugEntityResponseData = drugAdviceItemsResponse.data.content
      setAllDrugAdviceItems(drugEntityResponseData)
      setReorderedItems(
        drugEntityResponseData
          .filter((drugAdviceItem) => drugAdviceItem.enabled)
          .sort((o1, o2) => sortFn(o1.order, o2.order))
          .map((drugAdviceItem, idx) => ({ ...drugAdviceItem, order: idx })),
      )
    }
  }

  function handleDragEnd(results) {
    const { source, destination } = results
    if (source && destination && source?.index !== destination?.index) {
      setReorderedItems((prev) => {
        const newArray = array_move(prev, source.index, destination.index)
        var toReturn = false
        newArray?.forEach((oohMsg, idx) => {
          if (!toReturn) {
            if (oohMsg.id !== allDrugAdviceItems?.[idx]?.id) {
              toReturn = true
            }
          }
        })
        setChanges(toReturn)
        return newArray
      })
    }
  }

  async function Setup() {
    setLoading(true)
    const functions = [getDrugAdviceItems()]
    await Promise.all(functions)
    setLoading(false)
  }

  useEffect(() => {
    console.log({ reorderedItems })
  }, [reorderedItems])

  useEffect(() => {
    Setup()
  }, [])
  return (
    <>
      <DialogTitle>Re-Order drug advice items</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        {loading ? (
          <CircularProgress />
        ) : (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable id="dnd-faq-category" droppableId="Cats" type="group">
              {(provided) => (
                <List
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  sx={{ bgcolor: 'background.paper' }}
                >
                  <ListItem>
                    <Box>
                      <span>#</span>
                      <South fontSize="small" />
                    </Box>
                    <Box sx={{ width: '18px' }}></Box>
                    <ListItemText primary="Drug advice item" />
                  </ListItem>
                  {reorderedItems?.map((drugAdviceItem, idx) => (
                    <Draggable
                      key={drugAdviceItem.id}
                      draggableId={`${drugAdviceItem.id}`}
                      index={idx}
                    >
                      {(provided) => (
                        <div
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                        >
                          <ListItemButton id={drugAdviceItem.id}>
                            <ListItemText
                              primary={`#${idx + 1}.   ${drugAdviceItem?.title || 'Title Not Set'}`}
                            />
                            <ListItemIcon>
                              <DragHandle />
                            </ListItemIcon>
                          </ListItemButton>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="success"
          disabled={!changes}
          onClick={() => setShowConfirmation(true)}
        >
          Save
        </Button>
      </DialogActions>
      <ConfirmationModal
        action="submit"
        body="Do you want to save"
        visibility={showConfirmation}
        visibilitySetter={setShowConfirmation}
        onSubmitFunctions={[() => saveOrder()]}
      />
    </>
  )
}
