import { ONLINE_COURSE_EPISODE_OBJECT } from 'src/common/types/onlineCourse/onlineCourseEpisodeObject'
import { getUserAuthoritiesFromLocalStorage } from 'src/services/BrowserStorage/localStorageUtils'

export const SUPPORTED_FIELD_TYPES = {
  languages: 'languages',
  type: 'type',
  noOfEpisode: 'noOfEpisode',
  subscription: 'subscription',
  launchMinPlayer: 'launchMinPlayer',
  allowPreview: 'allowPreview',
  availableForPlan: 'availableForPlan',
  autoPlay: 'autoPlay',
  publishDate: 'publishDate',
  duration: 'duration',
  internalTitle: 'internalTitle',
  shortDesc: 'shortDesc',
  longDesc: 'longDesc',
  imageUpload: 'imageUpload',
  listabilityPolicy: 'listabilityPolicy',
  title: 'title',
  createdFor: 'createdFor',
  contributors: 'contributors',
  primaryTags: 'primaryTags',
  secondaryTags: 'secondaryTags',
  internalTags: 'internalTags',
  feelings: 'feelings',
  sessionType: 'sessionType',
  recording: 'recording',
  bgMusicEnabled: 'bgMusicEnabled',
  bgMusicId: 'bgMusicId',
  videoOrientation: 'videoOrientation',
  mentorFeedbackEnabled: 'mentorFeedbackEnabled',
  mentorFeedbackMandatory: 'mentorFeedbackMandatory',
  postureFeedbackEnabled: 'postureFeedbackEnabled',
  postureFeedbackMandatory: 'postureFeedbackMandatory',
  relativeCourses: 'relativeCourses',
  courseGroups: 'course-groups',
  slugInput: 'slug-input',
  guestList: 'guest-list',

  track: 'track',
  preInstructionsTrack: 'preInstructionsTrack',
  preparatoryTrack: 'preparatoryTrack',
  postInstructionsTrack: 'postInstructionsTrack',
}

export const SUPPORTED_ONLINE_COURSE_TRACK_OBJECT_TYPES = {
  preInstructions: { id: 'preInstructions', title: 'Pre Instructions' },
  preparatory: { id: 'preparatory', title: 'Preparatory Track' },
  meditation: { id: 'meditate', title: 'Main Track' },
  postInstructions: { id: 'postInstructions', title: 'Post Track' },
}

export const SUPPORTED_ONLINE_COURSE_TRACK_OBJECT_TYPES_VALUES = Object.values(
  SUPPORTED_ONLINE_COURSE_TRACK_OBJECT_TYPES,
)?.map((val) => val?.id)

export function getNumberOfSessionsMatchingToNumberOfEpisodes(
  numberOfEpisodes,
  sessions,
  courseCategory,
  mentorId,
) {
  if (numberOfEpisodes >= (sessions?.length || 0)) {
    return [
      ...(sessions || []),
      ...Array.from({ length: numberOfEpisodes - (sessions?.length || 0) }, (v, idx) => ({
        ...ONLINE_COURSE_EPISODE_OBJECT,
        category: [courseCategory],
        createdFor: mentorId,
      })),
    ]
  }
  const arrayToCheck = sessions?.slice(numberOfEpisodes, sessions?.length)?.map((session) => ({
    ...session,
    createdFor: mentorId,
  }))
  let finalSliceIndex = -1
  for (let i = 0; i < arrayToCheck?.length; i++) {
    if (!!checkIfEpisodeIsValid(arrayToCheck[i])) {
      finalSliceIndex = i
    }
  }

  const arrayThatWillExistForSure = sessions?.slice(0, numberOfEpisodes) || []
  const updatedArrayAfterSlice = arrayToCheck?.slice(0, finalSliceIndex + 1) || []
  return [...arrayThatWillExistForSure, ...updatedArrayAfterSlice]
}

function checkIfEpisodeIsValid(episode) {
  if (!!episode?.title) {
    return true
  }
  if (!!episode?.internalTitle) {
    return true
  }
  if (!!episode?.internalTitle) {
    return true
  }
  if (
    SUPPORTED_ONLINE_COURSE_TRACK_OBJECT_TYPES_VALUES?.some(
      (sessionTrackType) => !!episode?.steps?.[sessionTrackType]?.track?.url,
    )
  ) {
    return true
  }
  return episode?.sessionId
}

function checkDisabledForRoleAccess(roleAccess, roleType) {
  const accessForAdmin = roleAccess?.[roleType]
  if (accessForAdmin) {
    if (accessForAdmin?.level === 'readOnly') {
      return true
    }
    if (accessForAdmin?.level === 'editable' || accessForAdmin?.level === 'edit') {
      return false
    }
  }
  return false
}

export function getDisabledBasedOnRoleLevel(roleAccess, onlineCourse) {
  const authorities = getUserAuthoritiesFromLocalStorage()
  const isAdmin = authorities?.includes('ROLE_ADMIN')
  const isAppManager = authorities?.includes('ROLE_APP_MANAGER')
  const isSubAdmin = authorities?.includes('ROLE_SUB_ADMIN')

  let adminDisabled = false
  let appManagerDisabled = false
  let subAdminDisabled = false

  if (isAdmin) {
    adminDisabled = checkDisabledForRoleAccess(roleAccess, 'ROLE_ADMIN')
  }

  if (isAppManager) {
    appManagerDisabled = checkDisabledForRoleAccess(roleAccess, 'ROLE_APP_MANAGER')
  }

  if (isSubAdmin) {
    subAdminDisabled = checkDisabledForRoleAccess(roleAccess, 'ROLE_SUB_ADMIN')
  }

  return adminDisabled || appManagerDisabled || subAdminDisabled
}

//readOnly
//editable
