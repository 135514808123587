import { Checkbox, FormControlLabel, Grid } from '@mui/material'
import React from 'react'
import GridSectionWrapper from 'src/components/controlCenter/common/sectionComponents/GridSectionWrapper'
import { CCFormInput } from 'src/components/controlCenter/FormComponents'

export default function DrugAdviceNoteForm({
  initialObject,
  imageObject: drugAdviceEntity,
  setImageObject: setDrugAdviceEntity,
  entity,
  handleChangeObject,
  handleReactSelectChange,
  handleImageChange,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  allImages,
  handleRichTextChange,
  customOptions,
  disabled,
  hideEnabled = false,
  slugValidation,
  setSlugValidation,
  setTitleValid,
  titleValid,
  setSaving,
}) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={drugAdviceEntity?.availableToAll}
              onChange={(e) => handleChangeObject(e, 'availableToAll')}
            />
          }
          label="Available to all"
        />
      </Grid>
      <Grid item xs={12}>
        <CCFormInput
          id="order"
          label="Order"
          value={drugAdviceEntity?.order}
          onChange={handleChangeObject}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <CCFormInput
          id="title"
          label="Title"
          value={drugAdviceEntity?.title}
          onChange={handleChangeObject}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <GridSectionWrapper heading="Translations">
          <Grid item xs={12}>
            <CCFormInput
              id="title"
              label="Hindi Title"
              value={drugAdviceEntity?.translations?.hi?.title}
              onChange={(e) =>
                setDrugAdviceEntity((prev) => ({
                  ...prev,
                  translations: {
                    ...prev?.translations,
                    hi: {
                      ...(prev?.translations?.hi || {}),
                      title: e.target.value,
                    },
                  },
                }))
              }
              disabled={disabled}
            />
          </Grid>
        </GridSectionWrapper>
      </Grid>
    </Grid>
  )
}
